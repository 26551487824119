import XYZ from 'ol/source/XYZ.js';
import BingMaps from 'ol/source/BingMaps.js';
import Map from 'ol/Map.js';
import TileLayer from 'ol/layer/Tile.js';
import View from 'ol/View.js';
import MousePosition from 'ol/control/MousePosition.js';
import LayerSwitcher from 'ol-layerswitcher/dist/ol-layerswitcher.js';
import {Rotate, FullScreen, ScaleLine, defaults as defaultControls} from 'ol/control.js';
import {DragRotateAndZoom, defaults as defaultInteractions} from 'ol/interaction'
import {createStringXY} from 'ol/coordinate.js';
import {transform} from 'ol/proj';
import {cities, citiesZoom} from './maincity.json';

const mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(4),
  projection: 'EPSG:4326',
  className: 'custom-mouse-position',
  target: document.getElementById('mouse-position'),
});


const view = new View({
    center: transform([113.6, 22.9], 'EPSG:4326', 'EPSG:3857'),  
    projection: 'EPSG:3857',
    zoom: 9,
    maxZoom: 18,
    minZoom: 1,
    enableRotation: false,
  });

view.addEventListener("change", OnViewChange)
function OnViewChange(e){
  document.getElementById('zoom').innerText = "缩放:" + ( e ? e.target.values_.zoom.toFixed(2) : '12.99')
}
OnViewChange()

const lyrLoc  = new TileLayer({ title: "本地矢量",  visible: false, source: new XYZ({ url: '/data/tile/china/{z}/{x}/{y}.png' }) });
const lyrTian = new TileLayer({ title: "天地图",    visible: false, source: new XYZ({ url: 'http://t5.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=cf121be6477c5f052053cba441a969e7' }) });
const lyrGao6 = new TileLayer({ title: "高德影像",  visible: false, source: new XYZ({ url: 'http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=6&x={x}&y={y}&z={z}', wrapX: false }) });
const lyrGao7 = new TileLayer({ title: "高德矢量",  visible: true, source: new XYZ({ url: 'http://wprd0{1-4}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&style=7&x={x}&y={y}&z={z}', wrapX: false }) });
const lyrBing = new TileLayer({ title: "微软Bing",  visible: false, source: new BingMaps({ key: 'AjftV52e7g71rwZdqmX3fUaEGKPamEoq8acCSOHCksPpF8ztz0AGlX3hXhxnrgY1', imagerySet: 'Aerial'})});

const map = new Map({ target: "map",  view, layers: [lyrBing, lyrLoc, lyrGao6, lyrGao7, lyrTian], interactions: defaultInteractions().extend([new DragRotateAndZoom()]), 
  controls: defaultControls().extend([mousePositionControl, new LayerSwitcher(), new FullScreen(), new ScaleLine({unit:"metric", bar: true, minWidth: 100})]), });

const citybar = document.getElementById('city');
for(var o in cities){ citybar.innerHTML +=  '<button id=' + o + '>' + o + '</button>'; }
citybar.onclick = function(e){
  if(e.target.id in cities)
    view.animate({ projection: 'EPSG:3857', center: transform(cities[e.target.id], 'EPSG:4326', 'EPSG:3857'),  zoom: citiesZoom[e.target.id] ? citiesZoom[e.target.id] : 13 })
}

const opacityInput = document.getElementById('opacity-input');
const opacityOutput = document.getElementById('opacity-output');
function update() {
  const opacity = parseFloat(opacityInput.value);
  lyrGao7.setOpacity(opacity);
  opacityOutput.innerText = opacity.toFixed(2);
}
opacityInput.addEventListener('input', update);
update();